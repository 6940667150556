import React from "react";
import styles from "./banner.module.css";
import Carousel from "react-bootstrap/Carousel";
import { carouselContent } from "assets/data/carouselContent";


const Banner = () => {
  return (
    <div className={styles.banner}>
      <Carousel fade >
        {carouselContent.map((content, index) =>
          <Carousel.Item key={index}>
            <img
              className={styles.image}
              src={content.image}
              alt={content.title}
            />            
            <Carousel.Caption className={styles.text}>
              {/* <h1>
                {content.title}
              </h1>
              <p>
                {content.subTitle}
              </p> */}
            </Carousel.Caption>
          </Carousel.Item>
        )}
      </Carousel>
    </div>
  );
};

export default Banner;
