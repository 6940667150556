import React from "react";
import styles from "./goUp.module.css";
import UpButton from "assets/icons/up-arrow.png";


const GoUp = () => {
  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  return (
    <button className={styles["go-up"]} onClick={() => scrollUp()}>
      <img className={styles.image} src={UpButton} alt="go-up" />
    </button>
  );
};

export default GoUp;
