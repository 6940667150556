import React, { createContext, useEffect, useState } from "react";
import styles from "./navbar.module.css";
import { Link } from "react-router-dom";
import Logo from "assets/logo.png";
import NavButton from "assets/icons/nav-button.png";
import NavOptions from "components/navOptions/NavOptions";

const NavOpenContext = createContext();

const Navbar = ({ children }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [navOpen, setNavOpen] = useState(false);

  useEffect(() => {
    // get the window width to change the navbar accordingly
    const handleResize = () => setWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <NavOpenContext.Provider value={setNavOpen}>
        <nav className={styles["navbar"]}>
            <Link className={styles["mbp"]} to="/">
              <img src={Logo} className={styles["logo"]} alt="logo" />
              <div className={styles["name"]}>&nbsp;MBP Trust</div>
            </Link>

            {/* change navbar according to window width */}
            {width > 1000
              ? <NavOptions />
              : 
              <><button onClick={() => setNavOpen(!navOpen)}>
                  <img
                    className={styles["nav-button"]}
                    src={NavButton}
                    alt="navigation"
                  />
                </button>
                {navOpen && <NavOptions/>}
                </>}
            {children}
        </nav>
    </NavOpenContext.Provider>
  );
};

export default Navbar;
export {NavOpenContext};