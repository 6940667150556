import React from "react";
import styles from "./home.module.css";
import Banner from "components/banner/Banner";
import FadeIn from "components/FadeIn";
import Imp from "../../assets/images/home/Imp.jpg";
import Imp1 from "assets/images/Events/DIWALI CELEBRATION AT LOHARPDHA/IMG_5487.JPG";
import Imp2 from "assets/images/Work/Gallery/gallery8.jpg";
import Imp3 from "assets/images/Work/Gallery/gallery7.JPG";

const Home = () => {
  return (
    <div>
      <div className={styles["home"]}>
        <Banner />

        <Section
          title="Vision & Mission"
          mainText={`Our vision is to extend through all our efforts, love, kindness and
            compassion to the underprivileged. More predominantly providing the
            orphaned, destitute, mentally/physically challenged and tribal
            children with love and nourishment. Our mission is to provide basic
            amenities like food, clothing, school, medical facilities, place of
            worship and basic education for street children.`}
          points={[]}
          image={Imp2}
        />

        <Section
          title="Goals & Objectives"
          mainText={`MBP Trust's primary objective are to:`}
          points={[
            `meet the physical, mental, emotional and spiritual needs of
                orphaned and destitute children.`,
            `establish self sustaining shelters (Dharmashalaas) with clean,
                safe, comfortable, and loving environment.`,
            `provide children with training and access to the latest
                technology, concepts and ideas as well as time-tested
                traditions and ideas.`,
            `encourage inherent talents of children.`,
          ]}
          image={Imp1}
          right={true}
        />

        <Section
          title="Our Approach"
          mainText=""
          points={[
            `Our aim is to educate and motivate people to achieve their
                potential, backed by optimal usage of the funds collected. Before
                embarking on any of our objectives, we draw a well thorough
                plan which etches out the steps of implementation and resources
                to be used, especially for rehabilitation and social development
                of people in tribal areas.`,
            `Slum children welfare is provided by implementing donation
                boxes at various premises like shops and offices, and this helps
                mobilizes funds.`,
            `We appeal to people from all walks of life including socialites and
                celebrities who can donate to serve as helping hands.`,
          ]}
          image={Imp3}
        />
      </div>
      <div className={styles.registrationSection}>
        <img className={styles.imp} src={Imp} alt="imp" />
        <p className={styles.registrationText}>
          Registration No{" "}
          <span className={styles.registrationNumber}>E27388(Mumbai)</span>
        </p>
      </div>
    </div>
  );
};

const Section = ({ title, mainText, points, image, right = false }) => {
  return (
    <FadeIn>
      {/* odd sections have image on right */}
      <section className={`${styles.section} ${right ? styles.reverse : ``}`}>
        <div className={`${styles.text} ${right ? styles.right : ``}`}>
          <div className={styles["heading"]}>{title}</div>
          <div className={styles["content"]}>
            {mainText}

            {/* each bullet is an element in the points array */}
            <ul>
              {points.map((point, index) => (
                <li key={index}>{point}</li>
              ))}
            </ul>
          </div>
        </div>
        <img className={styles["image"]} src={image} alt={title} />
      </section>
    </FadeIn>
  );
};

export default Home;
