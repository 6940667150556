import Banner1 from "assets/images/carousel/Banner1_edited.JPG";
import Banner2 from "assets/images/carousel/Banner2_edited.JPG";
import Banner3 from "assets/images/carousel/Banner3_edited.JPG";

const carouselContent = [
  {
    image: Banner1,
    title: "title1",
    subTitle: "subTitle1"
  },
  {
    image: Banner2,
    title: "title2",
    subTitle: "subTitle2"
  },
  {
    image: Banner3,
    title: "title3",
    subTitle: "subTitle3"
  }
];

export { carouselContent };
