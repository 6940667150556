import React from "react";
import styles from "./about.module.css";
import { trustees } from "assets/data/trustees.js";
import FadeIn from "components/FadeIn";
import IMG1 from "assets/images/Work/MBP trust/IMG_3679.jpg";

const About = () => {
  return (
    <div className={styles["about"]}>
       
      <img src={IMG1} alt="MBP Trust" className={styles["cover"]} />
      <Section
        title="About MBP Trust"
        content={[
          `Founded in 2010, the Mangesh Bhagat Prathishtan endeavours to
            provide the basic amenities like food, clothing, basic education,
            vocation, training, medical facilities and a place of worship to
            tribal children destitute, needy, mentally & physically challenged/
            disabled children living on the street, and provide them with a safe
            & better living environment full of love kindness and compassion.`,
          `Along with catering to their physical, mental, emotional and
            spiritual needs, the Mangesh Bhagat Prathishtan, in order to make
            the children under their care independent, self-reliant, confident,
            offers their wards access to the latest technology, concepts, and
            ideas along with time-tested traditions and ideas. This also ensures
            that hidden inherent talents in these children are tapped into their
            full potential.`,
        ]}
      />
      <Section
        title="Trustee Introduction"
        content={[
          `I am working for street children since ten years. I feel strongly
            about spreading happiness and joy in life of street children. I
            would love to lend a hand to such children. The kids in orphanage
            (dharmashalaas) are taken care by social workers over there, but
            what about the shelter less children in Mumbai ? Thus my goal is to
            aid such kids by providing education, food, medicines, clothes
            etc. I am a dance director. I organize dance shows in Mumbai and all
            over Maharashtra. Hence I feel like training such handicapped &
            street children passing on my talent to them. I also work for tribal
            children. I hope and strongly desire that such children receive many
            helping hands and hence humbly request you to partner us in our
            mission, in whatever ways you can help us.`,
        ]}
      />

      {/* ---------------- quote ------------------ */}
      <div className={styles.quote}>
        <FadeIn duration={2} startPos={{ x: -1000, y: 0 }}>
          "A helping hand is better than praying lips"
        </FadeIn>
      </div>

      {/* ---------- panel of trusties ------------ */}
      <section className={styles.section}>
        <div className={styles.text} style={{ width: "100%" }}>
          <div className={styles.heading}>Panel of Trustees</div>
          <div className={styles.trustees}>
            {trustees.map((trustee, index) => (
              <Trustee
                key={index}
                name={trustee.name}
                post={trustee.post}
                image={trustee.image}
              />
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

const Section = ({ title, content }) => {
  return (
    <FadeIn>
      <section className={styles["section"]}>
        <div className={styles.text}>
          <div className={styles["heading"]}>{title}</div>
          {/* each paragraph is an element in the content array */}
          <div className={styles["content"]}>
            {content.map((p, index) => (
              <div key={index}>{p}</div>
            ))}
          </div>
        </div>
      </section>
    </FadeIn>
  );
};

//individual trustee
const Trustee = ({ name, post, image }) => {
  return (
    <div className={styles.trustee}>
      <div className={styles["image-holder"]}>
        <img className={styles.image} src={image} alt={name} />
      </div>
      <div className={styles.name}>{name}</div>
      <div className={styles.post}>{post}</div>
    </div>
  );
};

export default About;
