import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./ThankYou.module.css";

const ThankYou = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/");
    }, 5000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className={styles.container}>
      <h1 className="h1">Thank You for submitting your donation details!</h1>
      <p className="p">We appreciate your support</p>
      <p className="p">Redirecting you to the main page in 5 seconds...</p>
    </div>
  );
};

export default ThankYou;
