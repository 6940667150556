import React, { useState } from "react";
import styles from "./contact.module.css";
import FadeIn from "components/FadeIn";
import axios from "axios";
import FormInput from "components/formInput/FormInput";
import toast from "react-hot-toast";

const Mail = () => {
  const [loading, setLoading] = useState(false);

  const [mailData, setMailData] = useState({
    name: "",
    phone: "",
    email: "",
    message: ""
  });

  //--------- check if the given input is valid or not -----------
  const checkValidity = () => {
    const phoneRegex = /^[0-9]{10}$|^\+[0-9]{2,4}[0-9]{10}$/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    console.log("here")
    if (!phoneRegex.test(mailData.phone)) {
      toast.error("Invalid phone number format");
      return false;
    }

    if (!emailRegex.test(mailData.email)) {
      toast.error("Invalid email format");
      return false;
    }
    return true;
  };

  //------- handle the submission of the message --------
  const submitMail = async e => {
    setLoading(true);
    e.preventDefault();
    if (checkValidity() === false) return;

    // send message to server
    const url = `${process.env.REACT_APP_SERVER_URL}/mail`;

    try {
      const response = await axios.post(url, mailData, {
        headers: {
          "Content-Type": "application/json"
        }
      });

      toast.success(response.data);
      return;
    } catch (error) {
      console.error(`Error sending mail:- \n${error}`);
      toast.error("Error sending message");
      return;
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className={styles.mail}>
      <div className={styles.heading}>
        For any suggestion, queries or grievances, kindly write to us.
      </div>
      <FadeIn startPos={{ x: 700, y: 0 }} delay={0}>
        <form className={styles.form} onSubmit={submitMail}>
          <FormInput //name
            fieldTitle="Name"
            object={mailData}
            setObject={setMailData}
          />

          {/* ---------- contact info ---------- */}
          <div className={styles["contact-info"]}>
            <FormInput //phone
              fieldTitle="Phone"
              object={mailData}
              setObject={setMailData}
              placeHolder="Eg: +911234567890 or  1234567890"
            />
            <FormInput //email
              type="email"
              fieldTitle="Email"
              object={mailData}
              setObject={setMailData}
            />
          </div>

          {/* -------------- message --------------- */}
          <div className={styles["form-text"]}>
            <label className={styles["message-label"]}>
              Message <span style={{ color: "red", fontSize: "0.8em" }}>*</span>
            </label>
            <textarea
              className={styles.message}
              id="message"
              value={mailData.message}
              placeholder="Message ..."
              onChange={e => {
                let updatedMailData = { ...mailData };
                updatedMailData.message = e.target.value;
                setMailData(updatedMailData);
              }}
              required
            />
          </div>

          <button type="submit" disabled={loading} className={styles.submit}>
            {loading ? "Loading..." : "Send"}
          </button>
        </form>
      </FadeIn>
    </section>
  );
};

export default Mail;
