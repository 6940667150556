import React, { useEffect, useRef } from "react";
import { motion, useInView, useAnimation } from "framer-motion";

const FadeIn = ({
  children,
  startPos = { x: 0, y: 0 },
  endPos = { x: 0, y: 0 },
  duration = 1,
  delay = 0.1,
  width = "fit-content"
}) => {
  const ref = useRef(null);
  const inView = useInView(ref, { once: true });
  const fadeAnimation = useAnimation();

  useEffect(
    () => {
      if (inView) fadeAnimation.start("visible");
    },
    [inView]
  );

  return (
    <div ref={ref} style={{ position: "relative", width, overflow: "hidden" }}>
      <motion.div
        variants={{
          hidden: { opacity: 0, x: startPos.x, y: startPos.y },
          visible: { opacity: 1, x: endPos.x, y: endPos.y }
        }}
        initial="hidden"
        animate={fadeAnimation}
        transition={{ duration, delay }}
      >
        {children}
      </motion.div>
    </div>
  );
};

export default FadeIn;
