import React from "react";
import styles from "./contact.module.css";
import { Link } from "react-router-dom";
import FadeIn from "components/FadeIn";
import Mail from "./Mail";
import { contacts } from "assets/data/contacts";

const Contact = () => {
  return (
    <div className={styles.contact}>
      <ContactNum />
      <Mail />
    </div>
  );
};

/*********************************************************
                  Phone Contact Info
*********************************************************/

const ContactNum = () => {
  return (
    <section className={styles["contact-num"]}>
      <FadeIn startPos={{ x: -300, y: 0 }} delay={0}>

        {/* ----------- contact numbers ---------- */}
        <div className={styles.text}>CONTACT NUMBERS</div>
        <div className={styles.nums}>
          {contacts.phone.map((num, index) =>
            <div key={index} className={styles.phone}>
              <div className={styles.name}>
                {num.name}
              </div>
              <div style={{ fontSize: "1.2em" }}>
                {num.contact}
              </div>
            </div>
          )}
        </div>

        {/* ------------ email ------------- */}
        <div className={styles.text}>EMAIL ADDRESS </div>
        <Link to={`mailto:${contacts.email}`} className={styles.email}>
          {contacts.email}
        </Link>
      </FadeIn>
    </section>
  );
};
export default Contact;
