import React from "react";
import styles from "./achievements.module.css";
import FadeIn from "components/FadeIn";
import IMG1 from "assets/images/Work/Rukna Nai 2019/ach1.jpg";
import IMG3 from "assets/images/Work/Rukna Nai 2019/ach3.jpg";
import IMG4 from "assets/images/achievements/Award2.jpg"

const Achievements = () => {
  return (
    <div className={styles.home}>
      <div className={styles["cover-pic"]}>
        <img style={{position:"relative"}} src={IMG1} alt="MBP Trust" className={styles.cover} />
        <div style={{position: "absolute", top: "20%",zIndex: 10}}>
          <h1 className={styles["title"]}>Achievements</h1>
        </div>
      </div>
      <Section
        title="Rukna Nahi Award 2019"
        mainText={`We were honored to receive the Rukna Nahi Award in 2019 for our work with individuals with disabilities. We run a skill development center to support and empower people with disabilities `}
        image={IMG3}
        points={[]}
      />
      <Section
        title="Kalashram Award 2019"
        mainText={`We received the Kalashram Award in 2019 for our commitment to running the Street School. For the past 17 years, we have been dedicated to working with street children.`}
        image={IMG4}
        right={[true]}
      />
    </div>
  );
};

const Section = ({ title, mainText, points = [], image, right = false }) => {
  return (
    <FadeIn>
      <section className={`${styles.section} ${right ? styles.reverse : ""}`}>
        <div className={`${styles.text} ${right ? styles.right : ""}`}>
          <div className={styles.heading}>
            {title}
          </div>
          <div className={styles.content}>
            {mainText}
            {points.length > 0 && (
              <ul>
                {points.map((point, index) => (
                  <li key={index}>{point}</li>
                ))}
              </ul>
            )}
          </div>
        </div>
        <img className={`${styles.image} ${styles.imageHover}`} src={image} alt={title} />
      </section>
    </FadeIn>
  );
};

export default Achievements;

