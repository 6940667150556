/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import styles from "./work.module.css";
import FadeIn from "components/FadeIn";
import IMG2 from "assets/images/Work/MBP trust/Work.jpg";
import { useNavigate } from "react-router-dom";
import new1 from "../../assets/images/Work/new1.jpg";
import new3 from "../../assets/images/Work/new3.jpg";
import new2 from "../../assets/images/Work/new2.JPG";
import gallery1 from "../../assets/images/Work/Gallery/gallery1.jpg";
import gallery2 from "../../assets/images/Work/Gallery/gallery2.jpg";
import gallery3 from "../../assets/images/Work/Gallery/gallery3.jpg";
import gallery4 from "../../assets/images/Work/Gallery/gallery4.jpg";
import gallery5 from "../../assets/images/Work/Gallery/gallery5.JPG";
import gallery6 from "../../assets/images/Work/Gallery/gallery6.JPG";
import gallery7 from "../../assets/images/Work/Gallery/gallery7.JPG";
import gallery8 from "../../assets/images/Work/Gallery/gallery8.jpg";
import gallery9 from "../../assets/images/Work/Gallery/gallery9.JPG";
import gallery10 from "../../assets/images/Work/Gallery/Grid10.jpg";
import gallery11 from "../../assets/images/Work/Gallery/Grid11.jpg";
import gallery12 from "../../assets/images/Work/Gallery/Grid12.jpg";
import gallery13 from "../../assets/images/Work/Gallery/Grid13.jpg";
import gallery14 from "../../assets/images/Work/Gallery/Grid14.jpg";
import gallery15 from "../../assets/images/Work/Gallery/Grid15.jpg";
import gallery16 from "../../assets/images/Work/Gallery/Grid16.jpg";
import gallery17 from "../../assets/images/Work/Gallery/Grid17.jpg";
import gallery18 from "../../assets/images/Work/Gallery/Grid18.jpg";

const Work = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.work}>
      {/* Image Section */}
      <div className={styles["image-container"]}>
        <img src={IMG2} alt="MBP Trust" className={styles.cover} />
        <div className={styles["image-text"]}>
          Education is the movement from darkness to light
        </div>
      </div>

      {/* Our Impact Section */}
      <section className={styles.impact}>
        <FadeIn>
          <div className={styles.sectionContent}>
            <h2 className={styles.sectionTitle}>Our Impact</h2>
            <p className={styles.sectionDescription}>
              Education is not just about learning facts, but empowering
              individuals to thrive and contribute positively to society. Here’s
              how MBP Trust is making a difference
            </p>
            <div className={styles.impactGrid}>
              {/* Impact Cards */}
              <div className={styles.impactCard}>
                <h3 className={styles.cardTitle}>Children Empowered</h3>
                <p className={styles.cardText}>
                  Over 10,000 children provided with access to quality
                  education, enabling them to break the cycle of poverty.
                </p>
              </div>
              <div className={styles.impactCard}>
                <h3 className={styles.cardTitle}>Communities Transformed</h3>
                <p className={styles.cardText}>
                  50+ rural communities supported with infrastructure,
                  healthcare, and vocational training.
                </p>
              </div>
              <div className={styles.impactCard}>
                <h3 className={styles.cardTitle}>Innovative Programs</h3>
                <p className={styles.cardText}>
                  Introduction of STEM education and digital literacy programs
                  reaching schools nationwide.
                </p>
              </div>
            </div>
          </div>
        </FadeIn>
      </section>

      {/* Hero Section */}
      <section className={styles.hero}>
        <div className={styles.heroContent}>
          <h1 className={styles.heroTitle}>
            Transforming Lives Through Education
          </h1>
          <p className={styles.heroDescription}>
            At MBP Trust, we believe education is the key to a brighter future.
            Through our dedicated efforts, we empower unprivilaged children and
            communities across India.
          </p>
        </div>
      </section>

      {/* Stories of Change Section */}
      <section className={styles.stories}>
        <div className={styles.storiesContent}>
          <h2 className={styles.sectionTitle}>Stories of Change</h2>
          <div className={styles.storyCards}>
            {/* Story Card 1 */}
            <div className={styles.storyCard}>
              <div
                className={styles.cardImage}
                style={{
                  backgroundImage: `url(${new2})`,
                  backgroundPosition: "top",
                }}
              ></div>
              <div className={styles.cardContent}>
                <h3 className={styles.cardTitle}>Empowering Rural Youth</h3>
              </div>
            </div>
            {/* Story Card 2 */}
            <div className={styles.storyCard}>
              <div
                className={styles.cardImage}
                style={{
                  backgroundImage: `url(${new1})`,
                  backgroundPosition: "bottom",
                }}
              ></div>
              <div className={styles.cardContent}>
                <h3 className={styles.cardTitle}>Transforming Local Schools</h3>
              </div>
            </div>
            {/* Story Card 3 */}
            <div className={styles.storyCard}>
              <div
                className={styles.cardImage}
                style={{
                  backgroundImage: `url(${new3})`,
                  backgroundPosition: "center",
                }}
              ></div>
              <div className={styles.cardContent}>
                <h3 className={styles.cardTitle}>
                  Digital Literacy Initiative
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.gallery}>
        <h2 className={styles.sectionTitle}>Gallery</h2>
        <div className={styles.galleryGrid}>
          <img
            src={gallery1}
            alt="Gallery Image 1"
            className={styles.galleryImage}
          />
          <img
            src={gallery2}
            alt="Gallery Image 2"
            className={styles.galleryImage}
          />
          <img
            src={gallery3}
            alt="Gallery Image 3"
            className={styles.galleryImage}
          />
          <img
            src={gallery4}
            alt="Gallery Image 4"
            className={styles.galleryImage}
          />
          <img
            src={gallery5}
            alt="Gallery Image 5"
            className={styles.galleryImage}
          />
          <img
            src={gallery6}
            alt="Gallery Image 6"
            className={styles.galleryImage}
          />
          <img
            src={gallery7}
            alt="Gallery Image 7"
            className={styles.galleryImage}
          />
          <img
            src={gallery8}
            alt="Gallery Image 8"
            className={styles.galleryImage}
          />
          <img
            src={gallery9}
            alt="Gallery Image 9"
            className={styles.galleryImage}
          />
          <img
            src={gallery10}
            alt="Gallery Image 10"
            className={styles.galleryImage}
          />
          <img
            src={gallery11}
            style={{ backgroundPosition: "center" }}
            alt="Gallery Image 11"
            className={styles.galleryImage}
          />
          <img
            src={gallery12}
            alt="Gallery Image 12"
            className={styles.galleryImage}
          />
          <img
            src={gallery13}
            alt="Gallery Image 13"
            className={styles.galleryImage}
          />
          <img
            src={gallery14}
            alt="Gallery Image 14"
            className={styles.galleryImage}
          />
          <img
            src={gallery15}
            alt="Gallery Image 15"
            className={styles.galleryImage}
          />
          <img
            src={gallery16}
            alt="Gallery Image 16"
            className={styles.galleryImage}
          />
          <img
            src={gallery17}
            alt="Gallery Image 17"
            className={styles.galleryImage}
          />
          <img
            src={gallery18}
            alt="Gallery Image 18"
            className={styles.galleryImage}
          />
        </div>
      </section>

      {/* Donation Section */}
      <section
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
        className={styles.callToAction}
      >
        <div
          style={{ padding: "35px 0" }}
          className={styles.callToActionContent}
        >
          <h2 className={styles.mainTitle}>Join Us in Making A Difference</h2>
          <p className={styles.maindesc}>
            Your support can change lives. Donate today to help us continue our
            mission of empowering communities through education.
          </p>
          <button
            onClick={() => navigate("/donate")}
            className={styles.donateButton}
          >
            Donate Now
          </button>
        </div>
        <div
          style={{ height: "60px", width: "100vw", backgroundColor: "white" }}
        ></div>
        <div
          style={{ padding: "35px 0" }}
          className={styles.callToActionContent}
        >
          <h2 className={styles.mainTitle}>
            Join Us to Celebrate With Children
          </h2>
          <p className={styles.maindesc}>
            If you want to join us in celebrating with children, contact us
            below.
          </p>
          <button
            onClick={() => navigate("/contact-us")}
            className={styles.donateButton}
          >
            Contact Us
          </button>
        </div>
      </section>
    </div>
  );
};

export default Work;
