import React, { useContext, useEffect } from "react";
import styles from "./navOptions.module.css";
import { Link, useLocation } from "react-router-dom";
import { NavOpenContext } from "components/navbar/Navbar";

const navOptions = [
  { title: "Home", link: "/" },
  { title: "About Us", link: "/about-us" },
  { title: "Our Work", link: "/our-work" },
  { title: "Achievements", link: "/achievements" },
  // { title: "Upcoming Events", link: "/upcoming-events" },
  { title: "Contact Us", link: "/contact-us" }
];

const Option = ({ title, link }) => {
  const location = useLocation();
  const page = location.pathname.split("/")[1];
  
  useEffect(() => {
    if("/" + page === link)
      document.title = `MBP Trust | ${ title }`;
  }, [location]);

  const setNavOpen = useContext(NavOpenContext);
  return (
    <Link
      className={`${styles.option} ${"/" + page === link &&
        styles["active-option"]}`}
      to={link}
      onClick={() => setNavOpen(false)}
    >
      {title}
    </Link>
  );
};

const NavOptions = () => {
  const setNavOpen = useContext(NavOpenContext);
  return (
    <div className={styles["nav-options"]}>
      {navOptions.map((option, index) =>
        <Option key={index} title={option.title} link={option.link} />
      )}
      <Link
        className={styles["donate"]}
        to="/donate"
        onClick={() => setNavOpen(false)}
      >
        Donate
      </Link>
    </div>
  );
};

export default NavOptions;
