import React, { useState, useEffect } from "react"; // Import useEffect
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import styles from "./BankDetails.module.css";
import IMG1 from "assets/images/Bank/QR .png";

const BankDetails = () => {
  const [selectedPayment, setSelectedPayment] = useState("QR");
  const navigate = useNavigate();

  useEffect(() => {
    setSelectedPayment("QR");
  }, []);

  const handlePaymentSelection = paymentType => {
    setSelectedPayment(paymentType);
  };

  const renderPaymentDetails = () => {
    switch (selectedPayment) {
      case "QR":
        return <img src={IMG1} alt="QR Code" className={styles.qrImage} />;
      case "UPI":
        return (
          <div className={styles.paymentBox}>
            <div className={styles.text}>
              <b>
                <p>UPI ID: bhagatshyam21-1@okaxis</p>
              </b>
            </div>
          </div>
        );
      case "Bank":
        return (
          <div className={styles.paymentBox}>
            <p>
              Bank Name: <span>Bank of India</span>
            </p>
            <p>
              Branch Name: <span>Parel</span>
            </p>
            <p>
              Branch Address:{" "}
              <span>
                No.183, St. Xaviers Street, Parel, Mumbai, Maharashtra, India -
                400012 (Next to St Xaviers Ground)
              </span>
            </p>
            <p>
              Branch Tel: <span>4136246</span>
            </p>
            <p>
              Branch Email: <span>Parel.MumbaiSouth@bankofindia.co.in</span>
            </p>
            <p>
              IFSC Code: <span>BKID0000083</span>
            </p>
            <p>
              MICR Code: <span>400013077</span>
            </p>
            <p>
              Customer Id: <span>159433836</span>
            </p>
            <p>
              Account No: <span>008310110013405</span>
            </p>
            <p>
              Name: <span>Mangesh Bhagat Pratishtan</span>
            </p>
          </div>
        );
      default:
        return null;
    }
  };

  const handleNavigateToDonate = () => {
    navigate("/donation-form", { state: { selectedPayment } });
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.formText1}>Support us by your Donations</h1>

      <div className="container">
        <div className={styles.buttonGroup}>
          <button
            type="button"
            className={styles.pageButton}
            onClick={() => handlePaymentSelection("QR")}
          >
            QR Pay
          </button>

          <button
            type="button"
            className={styles.pageButton}
            onClick={() => handlePaymentSelection("UPI")}
          >
            UPI Pay
          </button>
          <button
            type="button"
            className={styles.pageButton}
            onClick={() => handlePaymentSelection("Bank")}
          >
            Bank Pay
          </button>
        </div>
        <div className={styles.paymentDetails}>
          {renderPaymentDetails()}
        </div>
        
        <h1 className={styles.formText}>Please fill this form after payment</h1>
        <button
          type="button"
          className={styles.pageButton}
          onClick={handleNavigateToDonate}
        >
          Claim Certificate Now
        </button>
      </div>
    </div>
  );
};

export default BankDetails;
