import React from "react";
import { Link, useLocation } from "react-router-dom";
import { FaFacebook, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";
import styles from "./Footer.module.css";

const sections = [
  { title: "Home", path: "/" },
  { title: "About us", path: "/about-us" },
  { title: "Our work", path: "/our-work" },
  { title: "Achievements", path: "/achievements" },
  // { title: "Upcoming events", path: "/upcoming-events" },
  { title: "Contact us", path: "/contact-us" },
  { title: "Donate", path: "/donate" },
];

const socialItems = [
  {
    name: "Facebook",
    icon: FaFacebook,
    link: "https://www.facebook.com/",
  },
  {
    name: "Instagram",
    icon: FaInstagram,
    link: "https://www.instagram.com/",
  },
  {
    name: "Twitter",
    icon: FaTwitter,
    link: "https://www.twitter.com/",
  },
  {
    name: "YouTube",
    icon: FaYoutube,
    link: "https://www.youtube.com/",
  },
];

const Footer = () => {
  return (
    <div className={`${styles["bg-yellow-custom"]} text-dark py-4`}>
      <div className={styles["container"]}>
        <div className="row">
          {sections.map((section, index) => (
            <div key={index} className={`col-md-3 ${styles["footer-section"]}`}>
              <h6>
                <Link style={{ color: "white" }} to={section.path}>
                  {section.title}
                </Link>
              </h6>
            </div>
          ))}
        </div>
        {/* <div className="row mt-3">
          <div className={`col-md-12 text-center ${styles["social-icons"]}`}>
            {socialItems.map((item, index) => (
              <a
                key={index}
                href={item.link}
                className={styles["social-link"]}
                target="_blank"
                rel="noopener noreferrer"
              >
                <item.icon size={30} />
              </a>
            ))}
          </div>
        </div> */}
        <div className={styles["footer-bottom"]}>
          &copy; 2024 MBP Trust. All rights reserved.
        </div>
      </div>
    </div>
  );
};

export default Footer;
