import React from "react";
import styles from "./donate.module.css";
import FormInput from "components/formInput/FormInput";
import { useNavigate } from "react-router-dom";
import DonationDateInput from "components/formInput/DonationDateInput";

const Details = ({ donorDetails, setDonorDetails }) => {
  const navigate = useNavigate();
  
  return (
    <div className={styles.details}>
      <div className={styles.heading}>Secure Your 80G Certificate Now </div>

      {/* ------ donor's personal details ------- */}
      <div className={styles.person}>
        <FormInput
          fieldTitle="Name"
          field="donorName"
          object={donorDetails}
          setObject={setDonorDetails}
          placeHolder="John Doe"
        />
        <FormInput
          type="email"
          fieldTitle="Email"
          field="email"
          object={donorDetails}
          setObject={setDonorDetails}
          placeHolder="example@domain.com"
        />
        <FormInput
          type="text"
          fieldTitle="Phone"
          field="phone"
          object={donorDetails}
          setObject={setDonorDetails}
          placeHolder="Eg: +911234567890 or 1234567890"
        />
        <FormInput
          fieldTitle="PAN Card Number"
          field="pan"
          object={donorDetails}
          setObject={setDonorDetails}
          placeHolder="ABCDE1234F"
        />
        <FormInput
          fieldTitle="Transaction Id"
          field="transactionId"
          object={donorDetails}
          setObject={setDonorDetails}
          placeHolder="Enter Valid Transaction ID"
        />
        <FormInput
          type="date"
          fieldTitle="Date of Birth"
          field="dob"
          object={donorDetails}
          setObject={setDonorDetails}
        />
        <DonationDateInput
          type="date"
          fieldTitle="Donation Date"
          field="donationDate"
          object={donorDetails}
          setObject={setDonorDetails}
        />
        <FormInput
          type="time"
          fieldTitle="Donation Time"
          field="donationTime"
          object={donorDetails}
          setObject={setDonorDetails}
        />
      </div>

      {/* --------- address of the donor ---------- */}
      <div className={styles.address}>
        <FormInput
          fieldTitle="Address"
          field="address"
          object={donorDetails}
          setObject={setDonorDetails}
          placeHolder="123 Main Street"
        />
        <FormInput
          type="text"
          fieldTitle="Postal Code"
          field="postalCode"
          object={donorDetails}
          setObject={setDonorDetails}
          placeHolder="110001"
        />
        <FormInput
          fieldTitle="City"
          field="city"
          object={donorDetails}
          setObject={setDonorDetails}
          placeHolder="New Delhi"
        />
        <div className={styles.states}>
          <label className={styles.label} htmlFor="states">
            State/ Union Territory
            <span style={{ color: "red", fontSize: "0.8em" }}>*</span>
          </label>
          <select
            className={styles.select}
            id="states"
            name="states"
            onChange={(e) =>
              setDonorDetails({ ...donorDetails, state: e.target.value })
            }
            required
          >
            <option defaultValue="">--- Select State/UT ----</option>
            {indianStatesAndUTs.sort().map((state, index) => (
              <option key={index} className={styles.option}>
                {state}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* --------- page buttons --------- */}
      <div className={styles.buttons}>
        <button
          type="button"
          className={styles["page-button"]}
          onClick={() => navigate("/donate")}
        >
          BACK
        </button>
        {/* <button
          type="button"
          className={styles["page-button"]}
          onClick={handleSubmit}
        >
          SUBMIT
        </button> */}
      </div>
    </div>
  );
};

const indianStatesAndUTs = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra & Nagar Haveli and Daman & Diu",
  "Delhi",
  "Jammu & Kashmir",
  "Ladakh",
  "Lakshadweep",
  "Puducherry",
];

export default Details;
