const contacts = {
  email: "mbptech8@gmail.com",
  phone: [
    {
      name: "Shyam Krishna Bhagat",
      contact: "+91 9869820822"
    },
    {
      name: "Asha Krishna Bhagat",
      contact: "+91 9137972614"
    },
  ]
};

export {contacts};