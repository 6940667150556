import Home from "pages/home/Home";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Layout from "components/Layout";
import About from "pages/about/About";
import Contact from "pages/contact/Contact";
import Work from "pages/work/Work";
import Achievements from "pages/achievements/Achievements";
import Donate from "pages/donate/Donate";
import { useEffect, useState } from "react";
import GoUp from "components/goUp/GoUp";
import BankDetails from "pages/BankDetails/BankDetails";
import ThankYou from "pages/ThankYou/Thankyou";

function App() {
  const [goUp, setGoUp] = useState(false);

  // go up button becomes visible when window has been scrolled more than 500px vertically
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 500) setGoUp(true);
      else setGoUp(false);
    });
  });

  return (
    <div className="App">
      {goUp && <GoUp />}
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/our-work" element={<Work />} />
          <Route path="/achievements" element={<Achievements />} />
          {/* <Route path="/upcoming-events" element={<UpcomingEvents />} /> */}
          <Route path="/donate" element={<BankDetails />} />
          <Route path="/donation-form" element={<Donate />} />
          <Route path="/thank-you" element={<ThankYou />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
