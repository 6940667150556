import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./donate.module.css";
import Details from "./Details";
import axios from "axios";
import amounts from "assets/data/amounts.json";
import toast from "react-hot-toast";

const Donate = () => {
  const location = useLocation();
  const { selectedPayment = "QR" } = location.state || {};

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    document.title = `MBP Trust | Donate`;
  }, [selectedPayment]);

  const [donorDetails, setDonorDetails] = useState({
    donationDate: "",
    donationTime: "",
    paymentMode: "",
    transactionId: "",
    donorName: "",
    amount: "0",
    pan: "",
    email: "",
    phone: "",
    state: "",
    city: "",
    postalCode: "",
    address: "",
    dob: "",
  });

  const checkValidity = () => {
    const phoneRegex = /^[0-9]{10}$|^\+[0-9]{2,4}[0-9]{10}$/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    const postalCodeRegex = /^\d{6}$/;

    if (!donorDetails.amount || donorDetails.amount === "" || donorDetails.amount <=0) {
      toast.error("Invalid amount set");
      return false;
    }

    if (!emailRegex.test(donorDetails.email)) {
      toast.error("Invalid email format");
      return false;
    }

    if (!phoneRegex.test(donorDetails.phone)) {
      toast.error("Invalid phone number format");
      return false;
    }

    if (!panRegex.test(donorDetails.pan)) {
      toast.error("Invalid PAN Card number");
      return false;
    }

    if (!postalCodeRegex.test(donorDetails.postalCode)) {
      toast.error("Invalid Postal Code");
      return false;
    }

    return true;
  };

  const submitDonationDetails = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!checkValidity()) {
      setLoading(false);
      return;
    }

    const donationDetails = { ...donorDetails, paymentMode: selectedPayment };
    const url = `${process.env.REACT_APP_SERVER_URL}/donate`;

    try {
      const response = await axios.post(url, donationDetails, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      toast.success(response.data);
      navigate("/thank-you");
      setLoading(false);
      return;
    } catch (error) {
      console.error(`Error sending :- \n${error}`);
      toast.error("Error sending donation details");
      setLoading(false);
      return;
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.donate}>
      {/* <Banner /> */}

      <form className={styles.form} onSubmit={submitDonationDetails}>
        <div className={styles.amount}>
          <div className={styles.heading}>Amount</div>
          <div className={styles.values}>
            {amounts.map((amount) => (
              <button
                key={amount}
                type="button"
                className={styles.value}
                onClick={() =>
                  setDonorDetails({ ...donorDetails, amount: amount })
                }
              >
                ₹ {amount.toLocaleString("en-IN")}
              </button>
            ))}
          </div>
          <label>Payment Method Selected : {selectedPayment}</label>
          <div className={styles.other}>
            <label className={styles["other-label"]}>₹ <span style={{ color: "red", fontSize: "0.8em" }}>*</span></label>
            <input
              type="number"
              className={styles["other-value"]}
              placeholder={donorDetails.amount.toLocaleString("en-IN")}
              value={donorDetails.amount}
              onChange={(e) =>
                setDonorDetails({ ...donorDetails, amount: e.target.value })
              }
              required
            /> 
          </div>

          <div className={styles.certificateInfo}>
            *The amount you donate will get you an 80G certificate if required.
          </div>
        </div>
        <Details
          donorDetails={donorDetails}
          setDonorDetails={setDonorDetails}
        />
        <button
          disabled={loading}
          type="submit"
          style={{ color: "white", backgroundColor: "var(--accent)" }}
          className={`${styles["page-button"]}`}
        >
          {loading ? "Loading..." : "Submit"}
        </button>
      </form>
    </div>
  );
};

const Banner = () => {
  return (
    <section className={styles.banner}>
      {/* <img
        className={styles["banner-image"]}
        src="images/landscape.jpg"
        alt="donate-banner"
      /> */}
      <div className={styles["banner-title"]}>DONATE FOR A CAUSE</div>
    </section>
  );
};

export default Donate;
