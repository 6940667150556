import Prachi from "assets/images/trustees/prachi-pramod-bhaide.jpg";
import Shyam from "assets/images/trustees/shyam-krishna-bhagat.jpg";
import Asha from "assets/images/trustees/asha-krishna-bhagat.jpg";
import Ashish from "assets/images/trustees/ashish-piyush-shah.jpg";

const trustees = [
  {
    name: "Shyam K. Bhagat",
    post: "Director",
    image: Shyam
  },
  {
    name: "Prachi P. Bhayade",
    post: "President",
    image: Prachi
  },
  {
    name: "Asha K. Bhagat",
    post: "Vice President",
    image: Asha
  },
  {
    name: "Ashish Shah",
    post: "Secretary",
    image: Ashish
  }
];

export { trustees };
