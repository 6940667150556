import React from "react";
import Navbar from "components/navbar/Navbar";
import Footer from "components/Footer/Footer";
import { Outlet } from "react-router-dom";

const Layout = () => {
  return (
    <div>
      <Navbar />
      
      {/* add the extra padding on top for content, since navbar is using position: fixed */}
      <div style={{
        paddingTop: "10vh"
      }}>
        <Outlet />
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
