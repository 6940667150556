import React from "react";
import styles from "./formInput.module.css";

const DonationDateInput = ({
  fieldTitle,
  field = fieldTitle.toLowerCase(),
  type = "text",
  object,
  setObject,
  placeHolder = fieldTitle,
  error
}) => {
  const today = new Date().toISOString().split("T")[0];

  return (
    <div className={styles["form-input"]}>
      <label className={styles.label} htmlFor={field}>
        {fieldTitle} <span style={{ color: "red", fontSize: "0.8em" }}>*</span>
      </label>
      <input
        className={styles.input}
        id={field}
        type={type}
        placeholder={placeHolder}
        value={object[field]}
        onChange={e => setObject({ ...object, [field]: e.target.value })}
        required
        max={type === "date" ? today : undefined}
      />
      {error &&
        <div className={styles.error}>
          {error}
        </div>}
    </div>
  );
};

export default DonationDateInput;
