import React from "react";
import styles from "./formInput.module.css";

/* Instead of individual fields, we take the whole object and then add the edited field to it. 
   This is done to make input component as universal as possible, so that they can be reused. */

const FormInput = ({
  fieldTitle,
  field = fieldTitle.toLowerCase(),
  type = "text",
  object,
  setObject,
  placeHolder = fieldTitle,
}) => {
  return (
    <div className={styles["form-input"]}>
      <label className={styles.label} htmlFor={field}>
        {fieldTitle} <span style={{ color: "red", fontSize: "0.8em" }}>*</span>
      </label>
      <input
        className={styles.input}
        id={field}
        type={type}
        placeholder={placeHolder}
        value={object[field]}
        onChange={(e) => setObject({ ...object, [field]: e.target.value })}
        required
      />
    </div>
  );
};

export default FormInput;
